const LocationReviewsData = [
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Clifford Oneal",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "William L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Dawn Vincent",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Larry H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Gloria Woods",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Susan J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Benjamin Wright",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Daniel M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Jamie Burke",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrew J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Kevin Ross",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Tamara Lopez",
        "service_type": "Garage Door Services",
        "tech_name": "Jackie D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Matthew Warren",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Emily R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Charles Rivera",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jillian H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Natalie Sanchez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Deborah M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Brandi Johns",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Aaron W.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Tanner Chapman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Laura B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "George Choi",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Andrew J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Reginald Lamb",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Richard G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Pamela Woodard",
        "service_type": "Garage Door Insulation",
        "tech_name": "Denise B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Erin Kennedy",
        "service_type": "Garage Door Installation",
        "tech_name": "Mark W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "John Contreras",
        "service_type": "Garage Door Services",
        "tech_name": "Michael H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Kathryn Anderson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nancy J.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Colleen Scott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sophia D.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Christopher Duarte",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sarah E.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Maria Thompson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Emma D.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Stephen Huang",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Edward G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "David Rivera",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alyssa C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "April Le",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Connor J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Robert Thompson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Timothy B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Stacey Garcia",
        "service_type": "Garage Door Services",
        "tech_name": "James B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Katie Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Patrick P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "John Norman",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David V.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Kathleen Huffman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kevin V.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Howard Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tyrone A.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "James Baxter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michelle C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Scott Jackson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tanya M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Stephanie Jensen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Paul C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Jill Rowe",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nicole C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Brent Reid",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Erin Hale",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dustin G.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Sarah Clark",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Emily S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Marc Chaney",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rebecca S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Dale Hogan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Angie W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Jessica Weiss",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alexander D.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Sandy Pratt",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Peggy Gordon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Catherine W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Leah Mason",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lindsay R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Barbara Stewart",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Julie K.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Jason Elliott",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tammy W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Teresa Gilbert",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Dave R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Laura Carr",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Walter G.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Jennifer Willis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Marissa G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Amanda Price",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kimberly B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Joshua Gonzales",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Heather B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Tim Martin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Julia A.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "John Lopez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Russell F.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Erin Morgan",
        "service_type": "Garage Door Services",
        "tech_name": "Fred J.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Vincent Hawkins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Dawn K.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Joseph Mcclain",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mark H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Jason Watson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Laura C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Jasmine Flores",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cody B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Suzanne Perez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "James S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Cody Thompson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Derek P.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Amber Yoder",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Theresa D.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Tracey Evans",
        "service_type": "Garage Door Repair",
        "tech_name": "Carlos K.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Peter Price",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Antonio P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Angela Buchanan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Anita G.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Thomas Fernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "George W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Laura Murray",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kristen R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Chung",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sharon G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Andrew Farley",
        "service_type": "Garage Door Insulation",
        "tech_name": "Abigail H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Cameron Brown",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nicholas M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Deanna Wong",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian L.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Christine Thompson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Barbara G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Sherry Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "William L.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Martha Cox",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joshua C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Melissa Murphy",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Patrick B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Kyle Frye",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dominic A.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Mark Frederick",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Aaron T.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Bobby Ruiz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Austin J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Carly Watson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gene L.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Kelly Spencer",
        "service_type": "Garage Door Installation",
        "tech_name": "Brian P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "James Santiago",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Adam T.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Sheena Lopez",
        "service_type": "Garage Door Services",
        "tech_name": "Michael C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Michael Weaver",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Cynthia S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Nicholas Garcia",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gabriel M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Shawn Horton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicole M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Laura Mcguire",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Richard H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Kari Andersen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Thomas C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Kelli Kennedy",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kendra S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Paul Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Samantha L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "April Morris",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Aaron Tran",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Nicole Warner",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Teresa D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Wesley Carroll",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Todd R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Stevens",
        "service_type": "Garage Door Opener",
        "tech_name": "Matthew P.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Sara Brooks",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Eric D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Austin Webb",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Scott P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Sandra Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Richard R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Baldwin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rebecca B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Tyler Ramos",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Edward A.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Amy Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Abigail C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Fitzgerald",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jane R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Russell Fitzpatrick",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joshua W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Richard Allen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Leslie G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Javier Bush",
        "service_type": "Garage Door Installation",
        "tech_name": "Brittany B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Kimberly Robinson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Adam R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Jeremy Moreno",
        "service_type": "Garage Door Services",
        "tech_name": "Chad R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Joseph Sanchez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Derrick C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Jordan Spencer",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joseph M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Brian Jacobs",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Elizabeth V.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Hayden Summers",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "William Hood",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cynthia F.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Mr. Timothy Bailey",
        "service_type": "Garage Door Repair",
        "tech_name": "Dylan N.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Laurie Cook",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Steven Y.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Joseph Rivera",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mariah W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Andrew Schmidt",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeremy E.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Samuel Molina",
        "service_type": "Garage Door Installation",
        "tech_name": "Zachary F.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Michael Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Holly L.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Joshua Williamson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Thomas B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Patrick Foley",
        "service_type": "Garage Door Insulation",
        "tech_name": "Pamela R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Jessica Cummings",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mary T.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "George Barton",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeremiah J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Nancy Vaughan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Wyatt W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Jasmine Lowe",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "David Morris",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jesus S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Sara Cox",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephen S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Shawn Rodriguez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brandon M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Kevin Graham",
        "service_type": "Garage Door Repair",
        "tech_name": "Angela W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Stephanie Rivera",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kenneth B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Jason Murphy DDS",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Eddie F.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Megan Wright",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Dennis Cannon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mckenzie R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Daniel Ferguson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jessica C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Vickie Arnold",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Morgan W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Hannah Castillo",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Donna A.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Laura Alexander",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Laura W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Edgar Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Laurie B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Gilbert Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jake P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Brandy Bell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Harry D.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Jordan Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Todd S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Mark Henderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ashley G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "William Rodriguez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kristen H.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Richard Ruiz",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stephen R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Paul Newman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Caroline Ruiz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brittany S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Jeremy Tyler",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Andrea G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Scott Nelson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sandra W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Romero",
        "service_type": "Garage Door Installation",
        "tech_name": "Brian S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Melissa Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kristen J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Melissa Schultz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ann M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Jennifer Taylor",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Shannon Harrington",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joseph W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Kara Diaz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Bethany B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Levi Walters",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christina T.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Deborah Fleming",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cynthia H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Gail Nelson",
        "service_type": "Garage Door Services",
        "tech_name": "Cheryl G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Brent Garcia",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Catherine Perez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kelli C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Brian Morris",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "William S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Taylor Stewart",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Allison W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Autumn Lara",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tamara L.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "John Jackson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Peter J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Mary Schmitt",
        "service_type": "Garage Door Repair",
        "tech_name": "John H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Renee Barnes",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Karen R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Harold Burke",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Eric Fernandez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Megan A.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Vanessa Nguyen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Joshua Montgomery",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shaun H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Katrina Whitehead",
        "service_type": "Garage Door Repair",
        "tech_name": "Renee T.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Stephen Kennedy",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rodney W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Patricia Reed",
        "service_type": "Garage Door Off Track",
        "tech_name": "Richard M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Madeline Gray",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kelly A.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Samantha Espinoza",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Anna A.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Frederick Reyes",
        "service_type": "Garage Door Repair",
        "tech_name": "Jeffrey H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Jimmy Lee",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Michael Martinez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert N.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Chris Vaughn",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "John Christensen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Tammy Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gregory B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Gabrielle Velez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Wanda B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "William Ramirez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Adrian H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Sanchez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeffrey J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Sarah Navarro",
        "service_type": "Garage Door Opener",
        "tech_name": "Robin C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Gray",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Marissa M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Corey Ramirez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kenneth L.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "William Meyers",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Wendy R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Katelyn Martin",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Andrew B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Derek Griffin",
        "service_type": "Garage Door Opener",
        "tech_name": "Megan K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Linda Hernandez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sean B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Hector Miller DVM",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kaitlyn G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Rebecca Garcia",
        "service_type": "Garage Door Insulation",
        "tech_name": "Timothy W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "David Nelson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jose Y.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Patricia Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Shane M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Leah Murphy",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Anthony C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Betty Brown",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ernest F.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "James Reyes",
        "service_type": "Garage Door Opener",
        "tech_name": "Heather M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Jennifer Reilly",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kathleen M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Charles Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anthony V.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Patrick Johnson",
        "service_type": "Garage Door Installation",
        "tech_name": "Karina F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Jerry Cook",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alexander W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Melissa Foster",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Elizabeth J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Ana Duncan",
        "service_type": "Garage Door Opener",
        "tech_name": "Angela D.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Cory Nguyen",
        "service_type": "Garage Door Installation",
        "tech_name": "Shawn A.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Nicole Patel",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jamie T.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Anthony Taylor",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Shelley R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Ashley Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jesse S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Ashley Hudson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael A.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Nicholas Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Katie A.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Linda Hancock",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lawrence C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Jennifer Norman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joseph P.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Jeremy Jacobs",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Clinton M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Mark Jones",
        "service_type": "Garage Door Repair",
        "tech_name": "Rebecca S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Katie Salazar",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Teresa S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Michael Brooks",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Jason Brock",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Andrea K.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Edward Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mark D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Theresa Taylor",
        "service_type": "Garage Door Services",
        "tech_name": "Brian H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Johnathan Patterson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer J.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Thomas Hill",
        "service_type": "Garage Door Services",
        "tech_name": "Joshua R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "David Castro",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Anthony W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Andrew Steele",
        "service_type": "Garage Door Installation",
        "tech_name": "Brian L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Dennis Freeman",
        "service_type": "Garage Door Insulation",
        "tech_name": "David J.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Stephanie Lopez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Barbara N.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Nancy Carter",
        "service_type": "Garage Door Installation",
        "tech_name": "Brittany L.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Gregory Marsh",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Susan R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Jennifer Burns",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "James Davis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mark C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "Shannon Dixon",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Charlotte V.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "James Ramirez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jamie H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Raven Ford",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicholas D.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Richard Cook",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Adam G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Leonard Rodriguez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Jennifer Knox",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Pamela P.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Mary Kelley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Julia L.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Mark Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paul C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Mark Coleman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Traci B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Karina Gonzalez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Susan J.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Justin Santos",
        "service_type": "Garage Door Off Track",
        "tech_name": "David M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Caitlin Walker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Ryan Gonzales",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Marcus R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Andrew Martin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melissa C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Carol Torres",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jonathan J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Lisa Wilson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Andrew R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Aaron Moon",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Valerie A.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Stephen Luna",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Diane W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Pamela Miller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Edward H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Paul Morris",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kimberly R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Robert Wright",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Christopher Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Bryan M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Kevin Butler",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kimberly W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Susan Brock",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tamara M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Travis Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brittany F.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Anthony Smith",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jim D.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Brittany Gibson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Phillip M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Michael Delgado",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer D.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "James Mays",
        "service_type": "Garage Door Repair",
        "tech_name": "Jacob M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Jason Escobar",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Terri V.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Victoria Moss",
        "service_type": "Garage Door Repair",
        "tech_name": "Kathy N.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Johnathan Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Thomas G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Laura Pena",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Joseph Hansen",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Edward W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Randy Sanchez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "William H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Benjamin Matthews",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Deanna S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Rhonda Diaz",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Julian S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Chase",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Terri O.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Albert House",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amanda L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Sarah Watson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Deanna F.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Joy Campos",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joseph J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Melissa Conley",
        "service_type": "Garage Door Opener",
        "tech_name": "April M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Phillip Taylor",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kevin H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Miranda Rivera",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kristy M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Daniel Church MD",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher J.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Jennifer Rodriguez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Connor D.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Jennifer Weaver",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jonathan R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Nicole Ramirez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Randy M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Dustin Hodge",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jessica G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Dawn Boyd",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brittany M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "James Anderson",
        "service_type": "Garage Door Repair",
        "tech_name": "Alex W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Mr. Dennis Myers",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Daisy S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "David Green",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Meghan A.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Mark Mayer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Blake S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Kathleen Washington",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Thomas R.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Sarah Soto",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "James Hall",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Phyllis R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Michael Richardson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Karla I.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Stephanie Wade",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Danielle Stuart PhD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Seth G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "David Jimenez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Timothy L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Stephen Collins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jason S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Albert Roberts",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Patricia G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Michael Garcia",
        "service_type": "Garage Door Repair",
        "tech_name": "Derrick C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Curtis West",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Melanie P.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Francisco Robinson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brian W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Joe Hill",
        "service_type": "Garage Door Installation",
        "tech_name": "Marcus S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Jeremy Jones",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stacy B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Kimberly Lee",
        "service_type": "Garage Door Insulation",
        "tech_name": "Benjamin J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Leah Williams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sandra B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Thomas Carrillo",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kelly D.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Casey Jackson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Jessica Pollard",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Maureen P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Sandra Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jordan E.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Kaitlyn Wade",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joshua B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Jessica Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Erica M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Katelyn Ramirez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Michael Wu Jr.",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ellen W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Michael Kennedy",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joshua K.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Suzanne Shannon",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Abigail L.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Carlos Baker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Laurie R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Mariah Garcia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Gary H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Tony Barrett",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Richard J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Mrs. Traci Gill MD",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Chad B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Ellen Mcgee",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Martin S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Justin Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Danielle M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Robert Torres",
        "service_type": "Residential Garage Door Services",
        "tech_name": "David G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Bradley Dougherty",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mary D.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Wanda Rodriguez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sarah L.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Sydney Harvey",
        "service_type": "Garage Door Services",
        "tech_name": "Katrina K.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Brandon Lee",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Angelica M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Jacob Hall",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nicolas G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Kimberly Gonzalez",
        "service_type": "Garage Door Installation",
        "tech_name": "April P.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Terri May",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "David Phillips",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Richard M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Deborah Mason",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Aaron J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "James Garcia",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lauren G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Teresa Kim",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Angela G.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "David Larsen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Catherine B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Melissa Bennett",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tracy O.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Brian Wood",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jasmine P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Don Evans",
        "service_type": "Garage Door Opener",
        "tech_name": "Nicole P.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Haley Curtis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tara W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Susan Knight",
        "service_type": "Garage Door Insulation",
        "tech_name": "Pamela S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Monica Reed",
        "service_type": "Garage Door Opener",
        "tech_name": "Keith C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Michelle Pitts",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Raymond M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Joanna Small",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Suzanne D.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Maria George",
        "service_type": "Garage Door Off Track",
        "tech_name": "Janet C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Gary Rhodes",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel E.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Morgan Russell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cindy R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Angel Cohen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Matthew R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Ricardo Edwards",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel L.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Jodi Moore",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "James G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Molly Dorsey",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Edward K.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Kimberly Butler",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sara S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "David Acevedo",
        "service_type": "Garage Door Off Track",
        "tech_name": "Maria C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Justin Ramirez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ronald B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Gregory Marshall",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jeffrey J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Thomas Peters",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alexis F.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Ian Welch",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cynthia B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Patrick Moore",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Keith G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Mario Hartman",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Paul H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "David Mccall",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Stacey Mccormick",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Melanie T.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Tara Gutierrez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kimberly A.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Heidi Mcintosh",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Melissa C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Marissa Hart",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mandy N.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Tara Armstrong",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gregory M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Austin Thompson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Edward M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Susan Browning",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Carla Ellis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Thomas F.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Adam Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brandon F.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Paul King",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Timothy C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "John Byrd",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Vickie P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Sarah Sharp",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Emily H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Stephanie Walker",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrea A.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Kristen Drake",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Charlene H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Shawn Stewart",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gary G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Robert Rios",
        "service_type": "Garage Door Services",
        "tech_name": "Austin M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Andres Hunter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Richard H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Arthur Sutton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Taylor O.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Edward Cole",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Hannah B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Cameron Salazar",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cynthia M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Jessica Barker",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joel S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Mark Mathews",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brandon M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Susan Page",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Hannah G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Edwin Thomas",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jessica G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Gordon Thomas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicole A.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Mark Kelly",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Diana C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Michael Dominguez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jerry L.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Bryan Ramos",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jocelyn B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Dalton Villegas",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Carol H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Joshua Robertson",
        "service_type": "Garage Door Services",
        "tech_name": "David C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Marissa Lewis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Dr. Isaiah Ortiz",
        "service_type": "Garage Door Installation",
        "tech_name": "George V.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Daniel Hernandez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Jessica Stanley",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Laura R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Deborah Walton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Alyssa Herring",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kevin H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Samuel Lloyd",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Thomas G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Heather Kelley",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Eric R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Ronald Thomas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Daniel B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Samantha Lara",
        "service_type": "Garage Door Services",
        "tech_name": "Melissa C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Kathy Leon",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Angela Vega",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mariah R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Joshua Aguilar",
        "service_type": "Garage Door Services",
        "tech_name": "Michelle V.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Christopher Peters",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Daniel B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Sarah Norman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Todd B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Christopher Holloway",
        "service_type": "Garage Door Insulation",
        "tech_name": "David M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Michelle Lopez",
        "service_type": "Garage Door Services",
        "tech_name": "Kristen L.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Joy Sullivan",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "James P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Shannon Fernandez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Johnny T.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Michele York",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kayla P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Casey Griffith DDS",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Thomas J.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Jonathan Barker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Calvin M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Jared Anderson",
        "service_type": "Garage Door Services",
        "tech_name": "Robert T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "John Valentine",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael D.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Michael Morris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cynthia B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Christopher Vargas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Keith J.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Maria Lowe",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephanie B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Taylor Wood",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Victor L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Julie Ramirez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Caitlyn L.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Jennifer Gilbert",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "April F.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Mr. Robert Anderson DVM",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Judy V.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "William Hernandez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Elizabeth O.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Susan Soto",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Matthew R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Jessica Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ethan S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Eric Price",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Cassidy Hernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gabriel D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Cassidy Rodriguez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Derek L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Emma Ball",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Natalie J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Glenn Moran",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joshua H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Craig Baker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Michael Sweeney",
        "service_type": "Garage Door Services",
        "tech_name": "Felicia W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Joshua Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Heather B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Christopher Bailey",
        "service_type": "Garage Door Services",
        "tech_name": "Katherine W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Richard Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brittney H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Lauren Garcia",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sonia R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Tracy Simpson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Margaret B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Walsh",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alyssa J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Samuel Luna",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lori V.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Elijah Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kayla D.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Nicholas Robles",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Vincent Lewis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Charles Walsh",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jared H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Andrew Pace",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Paula B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Jessica Flores",
        "service_type": "Garage Door Services",
        "tech_name": "Aaron C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Michelle Macias",
        "service_type": "Garage Door Opener",
        "tech_name": "Chris B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Crystal Ali",
        "service_type": "Garage Door Services",
        "tech_name": "Philip L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Christopher Williams",
        "service_type": "Garage Door Repair",
        "tech_name": "Nathaniel W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Karen Francis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Christina Owen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Diana S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Hernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Erika D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "John Dennis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Erin S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Bryan Gilmore",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Mark Ortiz",
        "service_type": "Garage Door Opener",
        "tech_name": "Wendy C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Mrs. Kelly Clark",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Miranda A.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Lisa Cervantes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Cindy A.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Erica Porter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Richard W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Charlene Moreno",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Michelle Adams",
        "service_type": "Garage Door Repair",
        "tech_name": "Tyler C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Benjamin Miller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brent M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Clayton Riley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christopher B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Nicholas Francis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Emily R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Kenneth Olsen",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Heather C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Christine Lamb",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christina B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Christopher Sandoval",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stanley M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Steven Foster",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly Y.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Tyler Ford",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Barbara N.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Andrew Thompson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Scott Zimmerman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lisa M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Todd Hogan",
        "service_type": "Garage Door Services",
        "tech_name": "Robin G.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Shannon Rodriguez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher O.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Mason Hicks",
        "service_type": "Garage Door Off Track",
        "tech_name": "Carlos S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Debbie Wells",
        "service_type": "Garage Door Services",
        "tech_name": "Julia H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Christopher Williams",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sean S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Dylan Castro",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lisa A.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Mark Forbes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephen G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Gregory Turner",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stephen P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Jesse Richardson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jenna M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Tina Ferguson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brian A.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Matthew Matthews",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kevin C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Michael Martinez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Veronica R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Jessica Rowe",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christine M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Desiree Mueller",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Derrick M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Jesse Williams Jr.",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joseph S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Martinez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Caitlin C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Blake Williams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kevin R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Jessica Bates",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Emma E.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Mark Taylor",
        "service_type": "Garage Door Services",
        "tech_name": "Justin R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Paul Reyes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gloria B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Heather Crawford",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amanda M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Megan Lee",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Walter H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Michelle Russell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christine P.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Mary Zimmerman",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sarah W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Michael Garcia",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alison S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Chad Sherman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "John M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Jesus Wilson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Madison Gray",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rodney C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Devon Walker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Melanie E.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Steven George",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Richard Lopez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kristen M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Larry Summers",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Matthew J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Dorothy Taylor",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Peter G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Leslie Sims",
        "service_type": "Garage Door Installation",
        "tech_name": "Alexandra S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Gina Bush",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Gerald Estrada",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christine J.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Steven Fisher",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Randall S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Hunter Edwards",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mark C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Cole Gibbs",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joel M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Barry Clayton",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Regina R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Beth Davis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nancy W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Susan Dunn",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Peter C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Anthony Nunez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jacqueline S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Sharon Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ashley L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Caitlin Montgomery",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "James O.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Jennifer Wright",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amy P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Amber Castaneda",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jasmin N.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Mrs. Colleen Simpson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sandra A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Alan Sellers",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Eddie T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Cody Martin DVM",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sylvia G.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Laura Orr",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Stephanie Davies",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Benjamin W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Charles Ellis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Rebecca Brooks",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Margaret Knight",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mark A.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Rodney Wise",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Daniel E.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Derrick Frost",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kristen C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Anthony Garrett",
        "service_type": "Garage Door Installation",
        "tech_name": "Marilyn H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Matthew Baker",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Taylor O.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Luis Diaz",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Pamela W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Angelica Harris",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kelsey S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Gerald Lee",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jared P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Merritt",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christine V.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Samantha Edwards",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Julie R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Jesse Thompson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mark W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Nathan Crawford",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Henry L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Kathy Hernandez",
        "service_type": "Garage Door Installation",
        "tech_name": "Katherine G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Scott Page",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Charles C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Carrie Roach",
        "service_type": "Garage Door Insulation",
        "tech_name": "Susan A.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Jesus Bowen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Patty R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Matthew Gill",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ashley J.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Brittney Davis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Charles M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Kathy Jenkins",
        "service_type": "Garage Door Opener",
        "tech_name": "Kevin P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Gabriel Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Dennis F.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Jeremy Mcgrath",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jonathan S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Rachel Payne",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ralph G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Phillip Molina",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Craig S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Stephen Young",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jay R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Sandra Davis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jessica S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Jamie Harrell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nicholas W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Nichole Johnston",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Taylor F.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Matthew Clark",
        "service_type": "Garage Door Installation",
        "tech_name": "Jesus M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Teresa Small",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David D.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Lisa Fisher",
        "service_type": "Garage Door Repair",
        "tech_name": "Brett M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Sara Wilson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Leslie W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Ryan Ward",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Jessica Gutierrez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ryan T.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Michelle Rowe",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brandi E.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Sheila Sherman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Matthew Arroyo",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Elizabeth W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Debra Luna",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "George M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Andrew Anderson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Karina S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Jessica Mendoza",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Larry T.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Brian Houston",
        "service_type": "Garage Door Installation",
        "tech_name": "Mary B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Robert Wood",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "James P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Don Welch",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Keith S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Sarah Strong",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sheri J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Zachary Jackson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Billy W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Lance Khan",
        "service_type": "Garage Door Opener",
        "tech_name": "Suzanne T.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Michelle Beck",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jamie B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Crystal Richmond",
        "service_type": "Garage Door Installation",
        "tech_name": "Tiffany K.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Deanna Cook",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Ashley Reed",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sara R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Larry Higgins",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mary H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Kenneth James",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jose C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Courtney Dominguez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Chelsea H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Ashley Jimenez MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Deborah K.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Ryan Hendricks",
        "service_type": "Garage Door Repair",
        "tech_name": "Karen J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Fernandez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Evan H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "James Eaton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Sandra Hahn",
        "service_type": "Garage Door Services",
        "tech_name": "Mary C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Mark Strong",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christopher B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Alice Charles",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas D.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Michael Brown",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Vanessa D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Darrell Flynn",
        "service_type": "Garage Door Repair",
        "tech_name": "William F.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Mariah Soto",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sara M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "John Long",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Willie B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Rebecca King",
        "service_type": "Garage Door Services",
        "tech_name": "Lori W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Daniel Gates",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jasmine O.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Kimberly Gates",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Katelyn W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Sarah Gonzalez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Patricia M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Dawn Olsen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Eric G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "David Hines",
        "service_type": "Garage Door Repair",
        "tech_name": "Randall A.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Christina Franco",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Terri Wells",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jeffrey G.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Amanda Brown",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Damon S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Susan Davis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brian B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Gina Harris",
        "service_type": "Garage Door Installation",
        "tech_name": "Ashley H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Rhonda Taylor",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jordan W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Lisa Adams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jesse M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Shannon Goodman",
        "service_type": "Garage Door Services",
        "tech_name": "Debra J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Shaun Yates",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "John Miller",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Larry M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Brandi Schwartz",
        "service_type": "Garage Door Installation",
        "tech_name": "Samuel G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Martin Cole",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Erica P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Martin Sparks DDS",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brian H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Meyers",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tammy S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "William Berger",
        "service_type": "Garage Door Installation",
        "tech_name": "Jose V.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Frank Hays",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert D.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Alexandria Landry",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Margaret R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Stephen Ball",
        "service_type": "Garage Door Insulation",
        "tech_name": "Donald H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Andre Rojas",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joseph R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Lisa Lopez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mark G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Daniel Robles",
        "service_type": "Garage Door Installation",
        "tech_name": "Melinda K.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Benjamin Valencia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jacob M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Amber Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Courtney P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Kristy Mcdaniel",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Bryan L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Cynthia Turner",
        "service_type": "Garage Door Services",
        "tech_name": "Jacob R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Mary Holmes DVM",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Shane B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Kathleen Becker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nicholas M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Carolyn Rodriguez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Julia W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Lynn Rose",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert A.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Lisa Madden",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mark J.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Christopher Rice",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brandon T.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Jamie Fisher",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mark H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Jaime Moran",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tyler W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Christine West",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stephanie B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Warren Carlson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephanie R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Courtney Olson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Roger Gonzalez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Elizabeth D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Cynthia Gordon",
        "service_type": "Garage Door Installation",
        "tech_name": "Jacqueline W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Marissa Edwards",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael K.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Vickie Mckenzie",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stephen P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Katherine Hanson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Jennifer White",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "David S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Timothy Garner",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jonathan C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Rebecca Massey",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melissa B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Dr. Karen Reyes MD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Adam H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Christopher Castillo",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gabrielle H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Brian Horne",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Don M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Kristina Patterson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Katherine B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Kenneth Bowers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stacie B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "John Freeman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melanie M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Noah Moreno",
        "service_type": "Garage Door Off Track",
        "tech_name": "Grace W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Nancy Ibarra",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steven S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Anthony Ortiz",
        "service_type": "Garage Door Services",
        "tech_name": "Douglas R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Anna Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Richard J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Charles White",
        "service_type": "Garage Door Off Track",
        "tech_name": "Yvonne L.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Nichole Espinoza",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Angela M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Diana Donovan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Allen T.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Rebecca Reed",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brenda H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Kurt Brown",
        "service_type": "Garage Door Services",
        "tech_name": "Douglas R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Margaret Ryan",
        "service_type": "Garage Door Repair",
        "tech_name": "Christopher M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Michael Gilbert",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stephanie W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "James Howell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dustin B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Gabriela Wall",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Laura L.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Tina Coffey",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeffrey D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Melissa Ortega",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brad S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "James Cobb",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Angela N.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Lisa Barrett",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Andrea T.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Joshua Hunter",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Martin G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Melanie Wolfe",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alexandra W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Kevin Grant",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kimberly S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Maria Thomas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Larry H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Sarah Bennett",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Richard A.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Robert Weaver",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher N.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Dana Sanders",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Deborah B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Anna Thomas",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alison R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Sandra Rodriguez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Dustin C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Kayla Blair",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Crystal S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Michael Roth",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tyler B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Willie Gilbert",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michelle R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Tony Green",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eric A.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "John Bullock",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Yvonne G.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "James Brown",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Marc R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Susan Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sean Z.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Michael Parks",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Parker",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bryan A.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Denise Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anna E.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Deanna Shah",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mary C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Jill Brooks",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Preston H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Shelby Castillo",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Allison White",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "George B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Maria Haney",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Matthew W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Timothy Potter",
        "service_type": "Garage Door Services",
        "tech_name": "Adam G.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Amy Glover",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Angelica Robbins",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Yvette Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kevin L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Kyle Jimenez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Deborah M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Mindy Henry",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Shirley P.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Jeremy Bell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christina P.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Martin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Randy C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Peter Johnston",
        "service_type": "Garage Door Opener",
        "tech_name": "Ashley Z.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Morgan Montgomery",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Chris M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Eugene Payne",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Annette B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Heather Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Susan W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Shannon Anderson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Aaron M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Brad Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Isaac S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "James Allen",
        "service_type": "Garage Door Opener",
        "tech_name": "Derek D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Tiffany Weber",
        "service_type": "Garage Door Repair",
        "tech_name": "Jennifer A.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Gina Salas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Theresa B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Laura Lin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John V.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Mary Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sara W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Luke Williams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Erik C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Phillip Foster",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Patrick O.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "William Martin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Connie Nguyen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amanda H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Mary Zimmerman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David V.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Jeremy Green",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Thomas J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Amy Young",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Brian Becker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Paul O.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Peter Frazier",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gloria G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Joseph Campbell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "George M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Natalie Hicks",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shannon M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Judith Ramirez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stacy B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Dylan Porter",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Barbara Key",
        "service_type": "Garage Door Installation",
        "tech_name": "Amy M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Yvette Hernandez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michelle T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Kristen Wall",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "David Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kevin W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Sabrina Foster",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Jennifer Livingston MD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Justin C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Jorge Wilkins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Richard S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Taylor Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Robin W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Joshua Quinn",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Chelsey S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Charles Koch",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jared S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Katrina Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Monique H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Diane Gamble",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ryan H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Kara Lamb",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cynthia L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Michael Morrison",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Eric J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Jose Kelly",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christine R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Ronald Conner",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stephanie W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Christopher Hernandez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Erin J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Manuel Walton",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephanie O.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Stephanie Fry",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Karen G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.8,
        "customer_name": "Justin Powers Jr.",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Pam Lin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Linda W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Michael Jones",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jerome M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Dennis Russo",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nicole G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Joanna Cervantes",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael J.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Kyle Orozco",
        "service_type": "Garage Door Insulation",
        "tech_name": "Dakota O.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Brian Rasmussen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Jane Freeman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Robert W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Morgan Gray",
        "service_type": "Garage Door Services",
        "tech_name": "Mary J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Danielle Nunez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Charles Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kellie E.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Cynthia Williamson",
        "service_type": "Garage Door Installation",
        "tech_name": "David W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Dominic Young",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Valerie M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Patricia Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Danielle B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Michael Paul",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Karen S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Erin Barrett",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kevin C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Heidi Oconnor",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jonathan D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Scott Campbell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brenda K.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Christian Schwartz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christopher C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Tina Conrad",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Jesus Howell",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amanda M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Joseph Taylor",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John E.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Michelle Goodman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Frank F.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Wanda Huang",
        "service_type": "Garage Door Opener",
        "tech_name": "Dawn R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Nicole Clark",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joseph F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Hayley Moore",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kimberly M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Teresa Richards",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joshua R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Victor Blackwell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Brenda Vargas",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Virginia B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Jonathan Anderson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jesse L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Daniel Carter",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carmen S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Leonard Calhoun",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Shawn S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Randy Trevino",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeffery J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Jeff Cook",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stephanie H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "David Grant",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Bill M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Chad Farmer",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sara S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Kristin Olson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jenna G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Noah Wilson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lauren R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Michael Watson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brianna Y.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Frank Schultz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Adam R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Ricardo Brown",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brooke A.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Donna Adams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Travis W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Timothy Holmes",
        "service_type": "Garage Door Services",
        "tech_name": "Mark H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Chad Holmes",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Charles P.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Breanna Gray",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicole B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Wendy Leonard",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sarah M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Robert Hill",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kimberly J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Annette Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Keith L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Micheal Sanchez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicholas R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Adriana Torres",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sarah S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Allison Morris",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "April W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Steve Martinez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Russell M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Jamie Klein",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "William M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Phillips",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Little",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kristopher G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Robert Mills",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Katie M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "William Gibson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Claire W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Albert Welch",
        "service_type": "Garage Door Services",
        "tech_name": "Gary M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Derrick Arnold",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Matthew Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Julie H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "David Ellison",
        "service_type": "Garage Door Opener",
        "tech_name": "Gabriela A.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Becky Davis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Bradley L.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Cody Key",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Blake M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Jerry Mcdonald",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christian F.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Antonio Taylor",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Jordan Ballard",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Darren J.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Joseph Greene",
        "service_type": "Garage Door Repair",
        "tech_name": "Jonathan M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "James Lawrence",
        "service_type": "Garage Door Opener",
        "tech_name": "Sarah L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Alexander Martinez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joshua H.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Christine Mccormick",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Jacob Tucker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Margaret L.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Eddie Cooper",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Johnny S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Jennifer Hodges",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Luis B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Kylie Schroeder",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Richard Patrick",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Madison B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Earl Welch",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Barbara F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Keith Simmons",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lynn B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Sharon Andrews",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mary D.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Stacey Wall",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tiffany J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Lindsey Bailey",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tiffany C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Jose Villa",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephanie G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Pamela Dillon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Leslie O.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Sarah Coffey",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Claudia A.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Kyle Wright",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Timothy Kelley",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Angelica O.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Roger Wolfe",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Timothy P.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Andrew Tate",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jesse R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Nathaniel Sutton",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Ronald Thornton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Heather G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Jennifer Medina",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Andrea R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Abigail Beasley",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lisa B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Sarah Carey",
        "service_type": "Garage Door Installation",
        "tech_name": "Brandon H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "William Bennett",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amanda B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Lisa Luna",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tony C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Kendra Mccann",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sandra P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Daniel Powell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ethan K.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Crystal Ballard",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Melissa D.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "James Dunn",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joshua V.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Eric Ramos",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Katherine M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Robert Taylor",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Emily R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Rhonda Bass",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Aaron T.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Alexis Nelson",
        "service_type": "Garage Door Services",
        "tech_name": "Michael M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Mr. Richard Lloyd",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Leah Ellis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joseph L.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Jenna Zamora",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Maria Steele",
        "service_type": "Garage Door Insulation",
        "tech_name": "Catherine S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Joseph Franco",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Maria H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "James Glover",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Corey C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Christopher Anderson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nathaniel G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Michael Williams",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph K.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Robert Kelly",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lance H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Joan Hunt",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Debbie H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Christopher Sanchez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ian M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Jason Mcdaniel",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tammy J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "James Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Deanna P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "David Galvan",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ashley P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Julie Maynard",
        "service_type": "Garage Door Off Track",
        "tech_name": "Diana S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Robert Carney",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Carlos J.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Caleb Lopez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jesse S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Christine Chavez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Arthur W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Megan Park",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Maria R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Kim Jones",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Carlos C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Deanna Petty",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kelsey L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Matthew Valdez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Bryan G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Nicole Scott",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Julie G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Robert Avery",
        "service_type": "Garage Door Opener",
        "tech_name": "Paul W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Brian Johnson",
        "service_type": "Garage Door Installation",
        "tech_name": "Brittany O.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Jesse Thomas",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Shelley W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Gregory Powell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jonathan J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Mark Grimes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mary M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Michael Green",
        "service_type": "Garage Door Insulation",
        "tech_name": "Richard M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Brandon Garcia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Karen S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Jesus Stark",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nancy B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Lisa Vargas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Mark Vazquez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andrew M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Luke Martin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "William F.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Eileen Rodriguez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Adam D.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Krystal Campbell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica V.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Robert Delacruz",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Peter B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Savannah Carr",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jamie P.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Mrs. Cassandra Henry",
        "service_type": "Garage Door Installation",
        "tech_name": "Jeremy P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Mary Lindsey",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kristin E.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Angela Chapman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nicolas N.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Natasha Green",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Betty H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Hector Burnett",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tamara C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Brianna Trujillo",
        "service_type": "Garage Door Services",
        "tech_name": "Brandon G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Aaron Dixon",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Laura J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Heidi Martinez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Eric C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Kyle Lang",
        "service_type": "Garage Door Repair",
        "tech_name": "Nancy M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Donna Whitehead",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Daryl R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Shannon Kelly",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Craig W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Michelle Allen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kevin P.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Justin Taylor",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Samuel B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Robin Brock",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Natalie H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Shane Dunn",
        "service_type": "Garage Door Off Track",
        "tech_name": "Destiny M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Katie Walter",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael N.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Jessica Bennett",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Benjamin G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Evelyn Lewis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sarah C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "David Mccoy",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kelly F.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Dana Welch",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elizabeth B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Samantha Robbins",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brad G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Shane Obrien",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa T.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Melissa Velez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "John G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "John Brennan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Desiree M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Madison Collins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bruce M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Sarah Newman",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brittany S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Kimberly Rice",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Benjamin H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Cassandra Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dominique M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Eddie Morse",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alison J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Holly Walton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kathy D.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Johnny Ortega",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Marcus M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Christine Myers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Patricia G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Melissa Logan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Julie R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Sherry Christensen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Annette H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Amy Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jacob S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Johnny Peters",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Janet G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Stacy Reed",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anita M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Dana Walker",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Krista S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Cynthia Hamilton",
        "service_type": "Garage Door Insulation",
        "tech_name": "Allison C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Tammy Lane",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mark R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Shawn Adams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sharon H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Donald Thomas",
        "service_type": "Garage Door Installation",
        "tech_name": "Matthew H.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Michael Love",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Bryan H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Karen Howell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Pamela W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Chelsea Perez",
        "service_type": "Garage Door Repair",
        "tech_name": "Alexandra P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Bailey Harrison",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Krystal L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Jonathan Hunter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David F.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "John Curry",
        "service_type": "Garage Door Opener",
        "tech_name": "Norman O.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Dawn Harrison",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joanne P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Barry Washington",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Andrew P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Eric Ellis",
        "service_type": "Garage Door Opener",
        "tech_name": "Richard K.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Bailey",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lisa H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Sandra Simmons",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mark R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Natalie Murray",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Antonio M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Meghan Bush",
        "service_type": "Garage Door Services",
        "tech_name": "Sharon R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Patrick Rodriguez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Chad Huffman",
        "service_type": "Garage Door Services",
        "tech_name": "Kathleen O.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Christina George",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Matthew H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Karen Murray",
        "service_type": "Garage Door Installation",
        "tech_name": "Dennis G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Christopher Soto",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Craig D.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Michael West",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Sandra Deleon",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Dennis H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Laura Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Erik G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Shirley Jackson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Christina Hardy",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Pamela T.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Brian Wilcox",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rebecca C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Andrew Carney",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mary C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Evelyn Grant",
        "service_type": "Garage Door Off Track",
        "tech_name": "David C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Timothy Sullivan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melanie E.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "John Simmons DVM",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kaitlyn T.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Anthony Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Amy W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Cory Bishop",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Allen P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Amber Andrews",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Mary Perez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Deborah L.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Rebecca Bradley",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jonathan H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Jennifer West",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Randall C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Stacey Clements",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Susan A.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Garrett Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Victoria T.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Joshua Willis",
        "service_type": "Garage Door Services",
        "tech_name": "Barbara J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Matthew Mcdonald",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Yvonne N.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Kimberly Brown MD",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Kayla Allison",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Julie A.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Brian Thompson",
        "service_type": "Garage Door Repair",
        "tech_name": "Matthew P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Mark Moreno",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James P.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Lisa Mendoza",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jose K.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Stephen Brooks",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Thomas B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Kaitlin Brown",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Christina Brown DDS",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lisa M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Crystal Mcguire",
        "service_type": "Garage Door Repair",
        "tech_name": "Sarah K.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Kathryn Mills",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "David Mckinney",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Kristin Baldwin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mary P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Geoffrey Harvey",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jacob A.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Katie Benjamin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amanda O.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Renee Watkins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Monica R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Bradley Short",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Donna R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Zachary Martin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Barbara H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Rachel Lamb",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Chris Clark",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Jennifer Bowers",
        "service_type": "Garage Door Repair",
        "tech_name": "Stephen S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Michael Gaines",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeremy W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Eddie Myers",
        "service_type": "Garage Door Opener",
        "tech_name": "Sarah W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Kenneth Avila",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jill N.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Jessica Tucker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Pamela F.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Richard Dunn",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jessica B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Nicholas Roberts",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kelly S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Melissa Davis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Justin K.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Tyler Reilly",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cassidy J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Jenna Gallagher",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Ashley Hutchinson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mary D.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Jasmine Greene",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kevin J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Jane Stafford",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Abigail H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Steven Camacho",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Shelby G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "John Luna",
        "service_type": "Garage Door Insulation",
        "tech_name": "Paula W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Timothy Rojas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jordan M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Robin Wells",
        "service_type": "Garage Door Repair",
        "tech_name": "Darlene R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Claire Blankenship",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jacob G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Nancy Ruiz",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Carl R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Toni Horton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Erica King",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amanda M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Joseph Collins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Maria C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Pamela Clark",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Heather L.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Jason Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Denise T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Mr. Kevin Phillips DVM",
        "service_type": "Garage Door Services",
        "tech_name": "Michael J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Kayla Rogers",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Charles R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Eileen Frazier",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alexander S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Holly Hernandez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Stacey S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Jeremy Miller MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Randy Z.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Ryan Watson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nicole P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Michael Wilkinson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Judy C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Ashley Rowe",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Zachary J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Ricky Walsh",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joshua L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Victor Diaz",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Darius H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Gregory Landry",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Anna M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Megan Mcclure",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Benjamin M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Timothy Jackson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amanda M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Tyler Gonzalez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joshua P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Joseph Hayes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Margaret M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Miss Ana Reyes",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Scott R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Calvin Garrett",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sandra F.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Mike Travis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alison L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Derek Arnold DDS",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jacqueline J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Matthew Holland",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Charles S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Donald Perez",
        "service_type": "Garage Door Repair",
        "tech_name": "Theresa D.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Mr. Shane Garner",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gregory R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Marcus Stephenson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joseph H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Amy Baird",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Brett Maddox",
        "service_type": "Garage Door Services",
        "tech_name": "Lindsay M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Patricia Ford",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Edward L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Miranda Adams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Laurie G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "George Edwards",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Deborah E.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Robert Lee",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Claudia W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Michele Ayers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Linda H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Jennifer Cooper",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Summer M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Curtis Landry",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jasmine R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Tyler Newman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Taylor P.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Michael Morgan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kristen L.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "William Hines",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Samantha S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Omar Clark",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Richard T.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Roy Cole",
        "service_type": "Garage Door Services",
        "tech_name": "Joshua G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Antonio Jones",
        "service_type": "Garage Door Services",
        "tech_name": "Teresa C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Ashley Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Alexandra B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Hunter Holt",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Valerie P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Carol Mccarthy",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Thomas Wilson",
        "service_type": "Garage Door Services",
        "tech_name": "Amy W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Joann Williams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christian H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Robert Mclaughlin",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Megan Fuller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer E.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Kevin Santiago",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mary S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Molly Sanchez",
        "service_type": "Garage Door Opener",
        "tech_name": "Douglas H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Shelley Melton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tracy S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Kelsey Clark",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mark R.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Paige Cooley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ronald H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Katie Carter",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kelly F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Samantha Harper",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicolas B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Hannah Long",
        "service_type": "Garage Door Services",
        "tech_name": "Heather J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Eric Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Savannah J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Paula Fernandez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michelle S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Laura Saunders",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jonathan F.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Stephanie Leon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer E.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Emily Kelley",
        "service_type": "Garage Door Repair",
        "tech_name": "Angela O.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Jason Cook",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Virginia Y.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Kenneth Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Brittany R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Joe Thomas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Susan Moore",
        "service_type": "Garage Door Off Track",
        "tech_name": "Angela K.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Sabrina Gibson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Corey F.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Thomas Green",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kathy F.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Dr. Lawrence Leon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Steve H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Blake Howard",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeffrey K.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Heather Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tammy L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Michael Gregory",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ryan S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Mary Frazier",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Melinda S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Jason Kim",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kayla G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Rachel Carlson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Leslie W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Kimberly Scott",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ashley P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Amanda Lee",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jose G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Devin Walsh",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Christina Watkins",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Timothy C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Sheri Logan",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Daniel Lewis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christina F.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Gerald Mitchell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Alexandra T.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Courtney Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jennifer W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Adriana Lindsey",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tony W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Brandon Reynolds",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Heather I.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Cynthia Huff",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brandon K.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Monica Espinoza",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kristi T.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Philip Scott",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ashley P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Mr. Paul Watson MD",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christopher H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Andrea Ross",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kirk M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Robyn Brown",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "April S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Stanley Walls",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Marie N.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Jason Calderon",
        "service_type": "Garage Door Repair",
        "tech_name": "Tamara N.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Eileen Stone MD",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Andrea N.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Samuel Shea",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jonathan C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Frederick Martinez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Gregory G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Anthony Jimenez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "David W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Amber Roman",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Louis N.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Katelyn Romero",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Tara Gomez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Steven C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Madeline Lloyd",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rachel M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Nicholas Parrish",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jasmine T.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Jeffery Arroyo",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Samantha M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Marc Lopez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew D.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Tara Davila",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Derek V.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Briana Ramsey",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Wayne W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Brian George",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jennifer S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Brian Jackson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Gloria C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Danielle Sanchez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Carl M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Sabrina Reynolds",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael A.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "John Hurley",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lisa C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "William Melendez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Justin M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Michelle Leon",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Bryan B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Sara Alexander",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Travis H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Manuel Howard",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jeffery N.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Kenneth Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alison R.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Shari Cook",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Crystal Mills",
        "service_type": "Garage Door Services",
        "tech_name": "Brandon M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Tyrone Thomas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alisha R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Michael Reyes",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joshua F.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Rachel Elliott",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "David K.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Ashley Molina",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Robert L.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Diana Sanders",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jenna M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Garrett Schneider",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jonathan P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Mary Bradley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Karen Curry",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gina B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Joel Simmons",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Max H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "David King",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jacqueline M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Nicholas Stewart",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jillian A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Eddie Powell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gary R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Christopher Shelton",
        "service_type": "Garage Door Services",
        "tech_name": "Diana M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Evelyn Gibson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Scott M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Susan Burgess",
        "service_type": "Garage Door Services",
        "tech_name": "Bobby S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Amy Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lisa G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Derrick Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jody W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Amy Pennington",
        "service_type": "Garage Door Services",
        "tech_name": "Billy N.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Michael Lara",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Garrett R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Andrew Ward",
        "service_type": "Garage Door Opener",
        "tech_name": "Kevin H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Kyle Pierce",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Megan B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "John Herring",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mary B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Christopher Peters",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Linda G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Michael Bailey",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Charles P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Luke Wyatt",
        "service_type": "Garage Door Services",
        "tech_name": "Miguel B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Patricia Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kristin W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Jerome Duncan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Frances W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Clarence Richards",
        "service_type": "Garage Door Off Track",
        "tech_name": "Charles K.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Miller",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cassandra W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "David Maxwell",
        "service_type": "Garage Door Services",
        "tech_name": "Jon T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Charles Davis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Danielle L.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "David Cross",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jacqueline J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Michaela Rodriguez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Anthony B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Gregory Beasley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Benjamin W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Stephen Bush",
        "service_type": "Garage Door Insulation",
        "tech_name": "Keith D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Corey Martinez MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brent E.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Kyle Davis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John D.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Richard Pittman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Elizabeth W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Sarah Powell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Becky G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Abigail Sanchez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Laura R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Ryan Perry",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stephen F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Denise Pierce",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Monique H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Tracy Doyle",
        "service_type": "Garage Door Off Track",
        "tech_name": "Angela M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Peter Wright",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Richard R.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Nicole Brooks",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Zachary M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Anthony Reed",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Daniel W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Lisa Bush",
        "service_type": "Garage Door Installation",
        "tech_name": "Alan S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Shannon Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Edward B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Michael Guerrero",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Duane B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Garrett Lopez",
        "service_type": "Garage Door Repair",
        "tech_name": "Dawn M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Rebecca Garcia",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sean F.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "William Rivera",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth D.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Carl Wood",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anna R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Ryan Juarez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Stephanie M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Amanda Jefferson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Andrew C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Glenn Torres",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Andrea G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Andre Frye",
        "service_type": "Garage Door Opener",
        "tech_name": "Dale R.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Ms. Nicole Sanchez",
        "service_type": "Garage Door Repair",
        "tech_name": "Jeffrey B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Zachary Lee",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sandra T.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Nicole Anderson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Heidi N.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Samantha Duncan",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lori L.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Jamie Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Beth H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Stephen Pierce",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lauren B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Joshua Zavala",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Shannon Walters",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Katelyn D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Terry Davenport",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nicholas J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Stephen Rivera",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Elizabeth J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Andrew Curry",
        "service_type": "Garage Door Services",
        "tech_name": "Willie L.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Christina Patterson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Anthony H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Jessica Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Emily J.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Michele Skinner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Monica Jones",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nicholas G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Donna Anderson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Erin S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Cathy Gibson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Grant Gonzales",
        "service_type": "Garage Door Installation",
        "tech_name": "Marcus S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "William Jones",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joseph L.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Margaret Pena",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Derek Cross",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jay D.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Robert Welch",
        "service_type": "Garage Door Off Track",
        "tech_name": "Elizabeth N.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Robert Sanchez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Melinda J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Cynthia Clark",
        "service_type": "Garage Door Repair",
        "tech_name": "Wanda J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Timothy Kramer",
        "service_type": "Garage Door Opener",
        "tech_name": "Kelly S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Susan Gay",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Stacey Ross",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Danielle W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Ernest Francis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "William S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "James Ross",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Donald R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Michael Johnston",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Heidi C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Thomas Jimenez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Keith P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Laura Jenkins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Julia B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Marc Oneill",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Luis L.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Angela Wilson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Vanessa M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Susan Collins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gavin T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Joel Castro",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Patrick B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Justin Lambert",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Holly S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Laura Olson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher Z.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Bill Mack",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Caroline L.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Gary Hoffman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mitchell S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Danielle Rice",
        "service_type": "Garage Door Services",
        "tech_name": "Andrew T.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Olivia Ford",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jessica C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Corey Ross",
        "service_type": "Garage Door Repair",
        "tech_name": "Robert G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Jessica Vasquez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Natalie M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Olivia Boyd",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Isaiah A.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Lopez MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Shane F.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Heather Daniels",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Joseph George",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kristy T.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Catherine Perkins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lucas L.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "David Flores",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Julie D.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "David Perez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Melissa York",
        "service_type": "Garage Door Insulation",
        "tech_name": "Julian P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "James Ryan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lisa C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Mrs. Jennifer Rios",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Caroline B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Erik Williams DDS",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Calvin A.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Kim Young",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tanya M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Pamela Valenzuela",
        "service_type": "Garage Door Insulation",
        "tech_name": "Adam S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Ashley Meyer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jonathan N.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "John Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lisa G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Randall Ramirez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kyle W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Timothy Rice",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christina A.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Christina Briggs",
        "service_type": "Garage Door Opener",
        "tech_name": "Richard T.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Jordan Moore",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeffery C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Cole Bowman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Patrick B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Gary Choi",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "James Lynch",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tyler H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "David Morris",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Paul M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Jack Jordan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Yolanda M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Annette Lynch MD",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Stephanie B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "David Mills",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Debra J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Kathy Thompson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Keith D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Sarah Hunter",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Christian Moore",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Larry S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Vincent Brown",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amy O.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Tamara Camacho",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mary T.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Timothy Martinez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Vanessa H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Connie Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Lauren S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Melissa Mendoza",
        "service_type": "Garage Door Opener",
        "tech_name": "Charles F.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Jason Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kelli W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Lynn Mccarthy",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tammy C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Maurice Hamilton",
        "service_type": "Garage Door Opener",
        "tech_name": "Jerry A.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Clayton Wood",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Marcia D.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "John Martin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Linda H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Briana Collins",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jeffrey R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Sharon Nelson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amber R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Martin Hill",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tina S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Dr. Terrence Brennan MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dale F.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Ashley Khan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Alicia H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Jordan Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brandy N.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Rita Aguilar",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Erica Austin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Raymond S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Jeremiah Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joy F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Kathryn Alvarez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stanley Z.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Xavier Bryan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James V.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Colin Miranda",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Richard M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Sandra Vance",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Charles M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "April Thompson",
        "service_type": "Garage Door Repair",
        "tech_name": "Adam M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Kimberly Anderson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeremy F.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Melissa Lester",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sarah B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Mark Sheppard",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Teresa M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Lori Bartlett",
        "service_type": "Garage Door Opener",
        "tech_name": "Troy N.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Gary Ortega",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Norman V.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "John Jones",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Andrea H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Todd Moreno",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stephanie T.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Kimberly Bowman",
        "service_type": "Garage Door Repair",
        "tech_name": "Robert D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Caitlyn Harris",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Douglas S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Amber Quinn",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Matthew R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Cheryl Williams",
        "service_type": "Garage Door Off Track",
        "tech_name": "David T.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Calvin Ellis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephen K.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Justin Waller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rebecca S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "James Hart",
        "service_type": "Garage Door Installation",
        "tech_name": "Anne H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Tommy Hall",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Carol J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Samantha Chambers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sandra W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Deborah White",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jane O.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Michael Moore",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeffrey B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Kendra Hensley",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Julie S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Julia Brown",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Patrick M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Juan Russell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rebecca A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Christopher Mendoza",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Madeline G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Anne Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jasmine A.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Michael Henson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Bianca J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Audrey Holland",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sabrina C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Chase Warner",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jodi F.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Sandra Roman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tyler K.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Tanya Lawrence",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeremy C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Heather Rodriguez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Teresa D.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Joseph Nicholson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Eduardo Chapman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Geoffrey K.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Heather Hunter",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Douglas W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Megan Velez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jimmy M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Walter Morrow",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jacob M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "David Thompson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Sabrina Martinez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Daniel Stephens",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly L.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "David Daniels",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sean H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "David Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Douglas M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Andrew Sanchez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Diane W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Paige Barnett",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Jennifer Gonzalez",
        "service_type": "Garage Door Repair",
        "tech_name": "Brad T.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Rickey Combs",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael G.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Nicholas Mcdonald",
        "service_type": "Garage Door Installation",
        "tech_name": "Brandi R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Andrew Jackson",
        "service_type": "Garage Door Installation",
        "tech_name": "Donald L.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Roberta Bishop",
        "service_type": "Garage Door Installation",
        "tech_name": "Jill B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Christine Nelson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ralph O.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Michelle Allen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Teresa M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Alan Webb",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mary P.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Amanda Mack",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeffrey M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Dave Holder",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mark A.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Kimberly Wade DVM",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shawn R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Ryan Patel",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robert S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Tina Bryant",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Dennis R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Scott Sharp",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Christopher Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Dawn N.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Angela Turner",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Monica W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Timothy Campbell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christian S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Daniel Clark",
        "service_type": "Garage Door Opener",
        "tech_name": "Diana W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Theresa Mcdonald",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael K.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Angela Harris",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Samantha S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Jesse Robbins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cameron T.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Paul Thomas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Justin M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Robert Daniels",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Noah J.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Maria Williams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John T.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Gary Castaneda",
        "service_type": "Garage Door Services",
        "tech_name": "Raymond V.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Stephanie Allen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kristin B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Joshua Powell",
        "service_type": "Garage Door Repair",
        "tech_name": "Joshua L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Heather Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeanette M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Angela Stevens",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Sean Washington",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Timothy M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Ashley Armstrong",
        "service_type": "Garage Door Services",
        "tech_name": "Jeffery S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Diane Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sarah B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Hannah Dougherty",
        "service_type": "Garage Door Services",
        "tech_name": "Nicholas H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Andrew Francis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Denise M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Eric Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Heather P.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Brian Wright",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ryan W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Michael Bradley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Monica F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Nicholas Hines",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Susan F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Sandy Galvan",
        "service_type": "Garage Door Opener",
        "tech_name": "Glen J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Matthew Porter",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sara W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Alexandra Green",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amanda S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Christopher Pacheco",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Renee S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Sean Ellis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Eric T.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Brian Brady",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "John Martin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brian W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "April Palmer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joseph V.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Dana Turner",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicole J.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Travis Turner",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Leslie W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "James Taylor",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Loretta H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Erin Jacobson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kelly B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Charles Johnston",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicholas S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Candace Martin",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sandra H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Mr. Michael Smith Jr.",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rebecca H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Maurice Kennedy",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeremiah M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Russell Todd",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Karen S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Richard Anderson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brian W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Melissa Reid",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Glenn M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Ashley Townsend",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joshua L.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Eric Stephens",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lauren C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Joseph Jimenez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michelle G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Lori Morris",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rebecca S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Kenneth Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alyssa J.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Jamie Reynolds",
        "service_type": "Garage Door Services",
        "tech_name": "Monica B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Michele Mueller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gary D.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Pamela Williams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sarah L.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Danielle Oconnor",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Charles D.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Kevin Butler",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tony A.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Nathaniel Castillo",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicholas S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Scott Bird",
        "service_type": "Garage Door Opener",
        "tech_name": "Melissa A.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Vincent Edwards",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Melissa B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Ashley Wagner",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Daniel C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Ricky Obrien",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Wesley N.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Derek Carlson DDS",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Carrie E.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Mary Allen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Monica M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "George Kidd",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Connie F.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Kyle Fernandez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Devon E.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Regina Bailey",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tyler C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Steve Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Madison M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Khan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Carla A.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Steven Smith DDS",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jason W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Juan Mccoy",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cynthia R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Julia Russell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tammy D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Norman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mark H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Linda Guerra",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sarah B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Sonya Howard",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Raymond S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Tina Brooks",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tyler S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Trevor Moore",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ruben M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Arthur Hunt",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cheryl D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Larry Ryan",
        "service_type": "Garage Door Repair",
        "tech_name": "Andrea W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Bruce Phillips",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Samuel Carpenter",
        "service_type": "Garage Door Installation",
        "tech_name": "William R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Steven Choi",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tyler S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Mary Cooke",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Austin Russell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Clinton W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Jenny Horton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mark D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Jason Cooper",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Chad Mills",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Daniel Massey",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Mark Taylor",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Elizabeth B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Baker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ricardo N.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Tammy Thomas",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Henry R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Patricia Montgomery",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Elizabeth D.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "James Bennett",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Wayne J.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Matthew Skinner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert T.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Rachel Simmons",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Caroline D.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Fernando Martin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Linda M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Brenda Meyer",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eric C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Julie Taylor",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nathan P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Rachel Davis",
        "service_type": "Garage Door Services",
        "tech_name": "Kevin M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Mike Francis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kevin S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Diane Phillips",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Leslie E.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Tonya Rivera",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brian B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Michael Zimmerman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Thomas B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Joseph Mclaughlin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Samantha L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Susan Martin",
        "service_type": "Garage Door Repair",
        "tech_name": "Johnathan B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Zachary Williams",
        "service_type": "Garage Door Services",
        "tech_name": "Michelle D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Chloe Hart",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sherri M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "Michael Jacobson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Chad T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Renee Cain",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jasmine C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Aaron Barron",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amanda W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Robert Anderson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Eric T.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Jordan Harrison",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brandon F.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Jessica Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Angel Alvarado",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Karina J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Brian Fernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joshua W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Mrs. Kathleen Robertson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michelle C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Carla Franco",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Edward R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Samuel Thomas",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lonnie S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Alexis Lopez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kristine H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Emily Johnson",
        "service_type": "Garage Door Installation",
        "tech_name": "Keith N.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Brian Sheppard",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Melanie C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "William Martin",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Aaron G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Erik Tran",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Timothy Lambert",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Deborah Mccann",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sandra W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Nicole Merritt",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Kyle Howard",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lawrence M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Stephanie Rodriguez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Miranda F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "David Austin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Peter R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Jessica Owens",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Laura H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Melissa Price",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Richard V.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Michelle Taylor",
        "service_type": "Garage Door Services",
        "tech_name": "April W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Deborah Cooper",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mary P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Lauren Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carlos H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Barbara Sandoval",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Micheal W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Bryce Fitzgerald",
        "service_type": "Garage Door Repair",
        "tech_name": "Jacob B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Jennifer Ortiz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher K.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Mary Robbins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Melanie J.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Nicholas Reed",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sarah J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Veronica Porter",
        "service_type": "Garage Door Installation",
        "tech_name": "Sheena P.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Daniel West",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Daniel H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Terrance Rodriguez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Andrew E.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Maria Pacheco",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Pamela G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Kylie Johnson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alec M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Micheal Rose",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Matthew A.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Curtis Wright",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jasmine W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Angela Reed",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jonathan C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Bridget Dodson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Karen G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
